@import "../node_modules/animatewithsass/animate.scss";
// see details in https://github.com/geoffgraham/animate.scss

//@import "animate.scss";

.fade-enter {
  opacity: 1;
  z-index: 1;
}

.fade-exit {
  opacity: 1;
  z-index: 1;
}

.fade-exit-done {
  opacity: 1;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  @include slideInRight(
    $duration: 0.5s,
    $count: 1,
    $delay: 0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}

.fade-exit.fade-exit-active {
  @include slideOutLeft(
    $duration: 0.5s,
    $count: 1,
    $delay: 0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}

.anim-pulse {
  @include pulse(
    $duration: 4s,
    $count: infinite,
    $delay: 0s,
    $function: ease,
    $fill: both
  );
  opacity: 1;
}

.anim-tada {
  @include tada(
    $duration: 10s,
    $count: infinite,
    $delay: 0s,
    $function: ease,
    $fill: both
  );
  @include keyframes(tada) {
    0% {
      @include transform(scale(1));
    }
    2%,
    4% {
      @include transform(scale(0.9) rotate(-3deg));
    }
    6%,
    10%,
    14%,
    18% {
      @include transform(scale(1.1) rotate(3deg));
    }
    8%,
    12%,
    16% {
      @include transform(scale(1.1) rotate(-3deg));
    }
    20% {
      @include transform(scale(1) rotate(0));
    }
    100% {
      @include transform(scale(1) rotate(0));
    }
  }
  opacity: 1;
}

//custom background glow
.glow-primary {
  animation: glow-primary 3s infinite;
  animation-direction: alternate;
}

@keyframes glow-primary {
  0% {
    background-color: unset;
    border-color: unset;
    border-radius: unset;
    border-width: 3px;
  }
  100% {
    border-color: #dc3545;
    border-radius: 25px;
    border-width: 3px;
    color: #dc3545;
  }
}

// opening div
.expandable-close {
  overflow: hidden;
  transition: all 1s ease-in-out;
  max-height: 0;
}

.expandable-open {
  overflow: hidden;
  transition: all 1s ease-in-out;
  max-height: 200px;
}
