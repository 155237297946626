$enable-shadows: true;

//$theme-colors: (
//  "light": #C1E6C3,
//  "dark": #294228
//);

@import "../node_modules/bootstrap/scss/bootstrap";

html,
body,
#root,
:root {
  height: 100%;
}

.App {
  background-color: white;
  //background-color: #C1E6C3;
  // color: white;
  color: #294228;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  word-break: break-word;
  white-space: break-spaces;
}

.App-header {
  width: 100%;
  //display: flex;
  text-align: center;
  // flex-direction: column;
  font-size: calc(10px + 2vmin);
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  color: #294228;
  border-bottom: 3px solid #56ba5b;
  padding: 0px;
  background-color: #56ba5b77;
}

.App-main {
  width: 100%;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.Page {
  overflow-y: visible;
  flex-flow: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-right: auto; // -0.5rem;
  margin-left: auto; // -0.5rem;
  margin-top: 0rem; // -0.5rem;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.App-link {
  color: #61dafb;
}

.FW {
  width: 100%;
}

.action-badge {
  position: absolute !important;
  bottom: -15% !important;
  right: -15% !important;
  top: unset !important;
}

.round-action {
  position: relative;
  font-size: initial !important;
  border-radius: 100% !important;
}

.round-action-xl {
  position: relative;
  font-size: x-large !important;
  border-radius: 100% !important;
}

.square-action-xl {
  position: relative;
  font-size: x-large !important;
  border: 0 !important;
  width: 64px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.round-action-sm {
  position: relative;
  font-size: small !important;
  border-radius: 100% !important;
}

.icon-button-xl {
  position: relative;
  font-size: x-large !important;
  padding: 0em 0.175em !important;
  border: 0;
  background-color: transparent !important;
}

.no-active:active,
.no-active:focus {
  background-color: initial !important;
  // color: $primary !important;
  border: initial !important;
  box-shadow: none !important;
  transition: 0s;
}

.color-invert {
  filter: invert(1);
}

.loading-background-animation {
  animation: loading-background-pulse 2s infinite;
}

@keyframes loading-background-pulse {
  0%,
  100% {
    background-color: #56ba5b;
  }
  50% {
    background-color: #294228;
  }
}

.clickable-superlight {
  transition: 0.6s;
  cursor: pointer;
}

.clickable-superlight:hover {
  background-color: #e3ffe4 !important;
}

.clickable-light {
  transition: 0.6s;
  cursor: pointer;
}

.clickable-light:hover {
  background-color: #56ba5b !important;
}

.nocarret .dropdown-toggle::after {
  display: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2L {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis3L {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg_white > path {
  fill: white;
}

.svg_black > path {
  fill: black;
}

//required by swipperjs
.swiper-container {
  width: 100%;
  height: 100%;
}

.modal-fs {
  width: 95%;
  max-width: 95%;
}

.modal-hs {
  width: 50%;
  max-width: 50%;
}

.modal-rich {
  width: 95vw;
  max-width: 95vw;
  max-height: 95vh;
  height: 95vh;
}
.modal-rich > .modal-content {
  height: inherit !important;
}

.modal-iframe {
  width: 95vw;
  max-width: 95vw;
  height: 95vh;
  max-height: 95vh;
  min-height: 95vh;
}
.modal-iframe > .modal-content {
  height: inherit !important;
}

.card-minimal > .card-header,
.card-minimal > .card-footer {
  background-color: #ffffffff;
  border-bottom: 0;
  border-top: 0;
}

.card-minimal > .card-header > h5,
.card-minimal > .card-header > h6 {
  margin-bottom: 0.1rem;
  line-height: 1.1;
}

.no-caret > .dropdown-toggle::after {
  visibility: hidden;
}
.no-caret > a {
  color: #56ba5b !important;
  padding: 0px !important;
}

.no-caret-white > a {
  color: #ffffff !important;
  padding: 0px !important;
}

.product-list-page {
  align-content: "flex-start";
  color: "black";
  // overflow-y: "auto"
}
